
import { useUserStore } from "@/store/UserStore";

export default {
    methods: {
        logout() {
            const UserStore = useUserStore();
            UserStore.reset()

            this.$cookies.remove('access_token', '/')
            this.$cookies.remove('refresh_token', '/')

            this.$router.push({name: 'auth.signin'})
        },
        refreshToken(callback) {
            const canRedirect = !(this.$route.name == 'auth.signin')
            const refreshToken = this.$cookies.get('refresh_token')

            if (!refreshToken) {
                return false
            }

            const url = process.env.VUE_APP_BASE_URL + '/connect/token'
            const username = process.env.VUE_APP_CLIENT
            const password = process.env.VUE_APP_CLIENT_SECRET
            
            const token = btoa(username + ':' + password)

            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: 'Basic ' + token
            }

            this.$http.post(url, new URLSearchParams({
                grant_type: 'refresh_token',
                refresh_token: refreshToken
            }), { headers }).then(
                res => {
                    // save in cookie access_token
                    this.$cookies.config(res.data.expires_in,'/');
                    this.$cookies.set('access_token', res.data.access_token);

                    const isFunction = typeof callback;

                    if (isFunction) {
                        callback()
                    }
                },
                error => {
                    console.log('refresh error' , error)

                    if (canRedirect) {
                        this.$router.push({name: 'auth.signin'})   
                    }
                }   
            )
        }
    }
};