<template>
    <main class="auth__layout">
        <div class="auth__container" :style="{'background-color': ThemeStore.loginPage.backgroundColor}">
            <AuthBackground />
            <div class="auth__container__main absolute__position">
                <div class="auth__container__main__description">
                    <div class="auth__container__main__content">
                        <img :src="ThemeStore.loginPage.logo.url" :alt="ThemeStore.loginPage.logo.name" class="auth__logo"/>
                        <h2 :style="leftTextColor">{{ ThemeStore.loginPage.text }}</h2>
                    </div>
                </div>
                <div class="auth__container__main__form" :style="authLayoutFormBackground">
                    <AuthSigninForm @processed="submit" :signinError="signinError"/>
                </div>
            </div>
        </div>
        <Footer class="signin__footer" />
    </main>
</template>
<script>
import { useThemeStore } from "@/store/ThemeStore";
import Footer from '@/components/Partials/Footer.vue'
import AuthSigninForm from '@/components/Auth/Signin/FormContent.vue'
import AuthBackground from '@/components/Auth/Signin/Background.vue'
export default {
    props: {
        signinError: Boolean,
        resetProcessed: Boolean,
        processing: Boolean
    },
    data() {
        const ThemeStore = useThemeStore();   
        return {
            ThemeStore: ThemeStore,
            LoginTheme: ThemeStore.loginPage,
        }
    },
    components: {
        Footer,
        AuthBackground,
        AuthSigninForm
    },
    computed: {
        leftTextColor () {
            return {'color': this.LoginTheme.leftTextColor}
        },
        authLayoutFormBackground() {
            const LoginTheme = this.LoginTheme
            
            return {
                'background-image': 'linear-gradient(to bottom, '
                + LoginTheme.loginColorTop + ', '
                + LoginTheme.loginColorBot + ')'
            }
        }
    },
    methods: {
        submit (res) {
            this.$emit('processed', {
                username: res.username,
                password: res.password,
                remember: res.remember
            })
        },
        togglePassword () {
            this.passwordType = this.passwordType == 'password' ? 'text' : 'password'
        }
    }
}
</script>
